/* basic START */
/* montserrat-300 - latin-ext_latin 

.CharlesWright-Bold {
	font-family: CharlesWright-Bold;
}.CharlesWright-BoldOblique {
	font-family: CharlesWright-BoldOblique;
}.CharlesWright-Oblique {
	font-family: CharlesWright-Oblique;
}.CharlesWright-Regular {
	font-family: CharlesWright-Regular;
}.CharlesWright1935 {
	font-family: CharlesWright1935;
}.CharlesWrightBoldCaps {
	font-family: CharlesWrightBoldCaps;
}.CharlesWrightMedium-Oblique {
	font-family: CharlesWrightMedium-Oblique;
}.CharlesWrightMedium {
	font-family: CharlesWrightMedium;
}.CharlesWrightMotorcycle {
	font-family: CharlesWrightMotorcycle;
}
*/

html > * {
	color: $txt;
}
body {
	background-color: $white;
	font-family: CharlesWright-Regular, sans-serif;
	font-size: $base-font-size;
	line-height: $base-linehight;
	font-weight: 400;
	margin: 0;
	padding: 0px 0px 0px 0px;
	color: $txt;
	letter-spacing: 0.2rem;
	position: relative;
	z-index: 1;
	@media (max-width: 1300px) {
		letter-spacing: 0.1rem;
	}
}
* {
	color: $txt;
}
a,
img {
	border: none;
	outline: none;
}

html {
	font-size: $base-font-size;
	line-height: $base-linehight;
	margin: 0;
	padding: 0;
	color: $txt;
	scroll-behavior: smooth;
	@media (max-width: 1300px) {
		font-size: $base-font-size * 0.9;
	}
}

strong,
b {
	font-family: CharlesWright-Bold, sans-serif;

	font-weight: bold;
	font-weight: 700;
}

i,
em {
	color: $txt;
	font-family: CharlesWright-Oblique, sans-serif;
}

input {
	font-family: CharlesWright-Regular, sans-serif;
	font-weight: 400;
	color: $txt;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	color: $txt;
	font-family: CharlesWright-Bold, sans-serif;
	font-weight: 900;
}

h1 {
	font-size: 1.6rem;
}
h2 {
	font-size: 1.3rem;
}
h3 {
	font-size: 1.1rem;
}
a {
	cursor: pointer;
	color: $txt;
}
p {
	padding: 0;
	margin: 0px 0px $space 0px;
}
.inner {
	padding-left: 100px !important;
	padding-right: 100px !important;

	@include b-TS() {
		padding-left: 50px !important;
		padding-right: 50px !important;
	}
	@include b-ML() {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}
.cont {
	//background-color: blue;
	max-width: $basic-width;
	box-sizing: border-box;
	//max-width: calc(100% - (2 * #{$spaceBig}));
	width: calc(100%);

	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 0;
}

.full-img {
	width: 100%;
}

.dev {
	width: 300px;
	position: fixed;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	color: $white;
	display: block;
	padding: 10px;
}

/* BOOTSTRAP OVERWRITE STOP */
/* PARALAX */
.paraX {
	opacity: 0;
}
@keyframes para {
	from {
		transform: translateY(30px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes paraL {
	from {
		transform: translateX(30px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes paraR {
	from {
		transform: translateX(-30px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
.paraAnim {
	animation: para 1s ease-in-out 0s 1 forwards;
}

.paraAnimR {
	animation: paraR 1s ease-in-out 0s 1 forwards;
}

.paraAnim-5 {
	opacity: 0;
	animation: para 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-5L {
	opacity: 0;
	animation: paraL 1s ease-in-out 0.5s 1 forwards;
}

@keyframes paraOpaOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.paraOpacity10 {
	animation: paraOpaOut 1s ease-in-out 0s 1 forwards;
}

@keyframes paraOpaIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.paraOpacity01 {
	animation: paraOpaOut 1s ease-in-out 0s 1 forwards;
}

/* PARALAX STOP */
