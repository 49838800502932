@mixin lineBig {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='8%2c 35' stroke-dashoffset='8' stroke-linecap='round'/%3e%3c/svg%3e");
}

@mixin lineShort {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='8%2c 35' stroke-dashoffset='8' stroke-linecap='round'/%3e%3c/svg%3e");
}
@mixin lineDotted(
	$fontSize: 1rem,
	$bottom: -5px,
	$color: $white,
	$height: 20px,
	$textAlign: right
) {
	position: relative;
	&:before {
		text-align: $textAlign;
		position: absolute;
		width: 100%;
		height: $height;
		left: 0px;
		bottom: $bottom;
		color: $color;
		font-size: $fontSize;
		content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ";
		overflow: hidden;
	}
}
