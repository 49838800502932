#startBlackBox {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-color: #000000;
	z-index: 1000000000;
	animation: paraOpaOut 0.75s ease-in-out 0s 1 forwards;
}
.leaveBlackBox {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-color: #000000;
	z-index: 1000000000;
	animation: paraOpaIn 0.75s ease-in-out 0s 1 forwards;
}

.topHeader {
	.cont {
		min-height: calc(90vh - 50px);
		background-image: url("../img/top-img.jpg");
		background-size: cover;
		background-position: center center;
		padding-top: 70px;
		padding-bottom: 70px;

		@include b-TS() {
			padding-top: 50px !important;
			padding-bottom: 50px !important;
		}

		@include b-ML() {
			padding-top: 25px !important;
			padding-bottom: 25px !important;
		}
	}
	.brand {
		width: 250px;
		height: 190px;
		margin-left: auto;
		display: block;

		@include b-TS() {
			width: 180px;
			height: 137px;
			margin-left: auto;
			display: block;
		}

		@include b-ML() {
			width: 100px;
			height: auto;
			position: absolute;
			right: 15px;
			top: 30px;
		}
	}

	.navi {
		color: $white;
	}

	a {
		display: block;
		text-align: right;
		color: $white;
		@include trans();
		text-decoration: none;
		&:hover {
			color: $orange;
			@include trans();
		}

		@include b-ML() {
			font-size: 0.8rem;
		}
	}

	.headHolder {
		display: flex;
		align-items: stretch;
		@include b-ML() {
			flex-wrap: wrap;
		}
		.head {
			box-sizing: border-box;
			flex: 1;
			display: flex;
			align-items: center;
			@include b-ML() {
				width: 100%;
				margin-bottom: 20px;
			}
			h1 {
				color: $white;
				font-size: 3rem;
				padding-left: 150px;
				@media (max-width: 1090px) {
					font-size: 2.2rem;
					padding-left: 50px;
				}
				@include b-TL() {
					padding-left: 0px;
				}
				@include b-ML() {
					font-size: 1.5rem;
				}
				@include b-M() {
					font-size: 1rem;
					margin-top: 25%;
				}
			}
		}

		.navi {
			box-sizing: border-box;
			width: 400px;
			padding-left: 50px;
			@include b-TS() {
				width: 300px;
				padding-left: 20px;
			}
			@include b-ML() {
				width: 100%;
				padding-left: 0px;
			}
		}
	}
	.mainMenu {
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;

			li {
				margin-bottom: 3px;
			}
		}
	}
	.firstMenu {
		padding-bottom: 20px !important;
		margin-bottom: 20px !important;
		position: relative;
		@include lineDotted();
	}
}

.boxGallery {
	h1 {
		font-size: 1.25rem;
		text-transform: uppercase;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	&--breadcrumb {
		font-size: 1.25rem;
		text-transform: uppercase;
		padding-top: 80px;
		padding-bottom: 30px;
		font-family: CharlesWright-Bold, sans-serif;
		font-weight: 900;
		box-sizing: border-box;
		padding-right: 170px !important;
		line-height: 130%;

		@include b-TS() {
			padding-right: 120px !important;
		}
		@include b-ML() {
			padding-right: 90px !important;
		}
		a {
			font-size: 1.25rem;
			text-transform: uppercase;
			color: $txt;
			text-decoration: none;
			@include trans();
			&:hover {
				@include trans();
				color: $orange;
			}
		}
	}

	&--wrap {
		display: flex;
		flex-wrap: wrap;
	}

	&--item {
		width: 33.3%;
		box-sizing: border-box;

		@include b-TL {
			width: 50%;
		}

		@include b-ML {
			width: 100%;
		}
		img {
			width: 100%;
		}
		position: relative;
		&:hover .boxGallery--txt {
			@include trans();
			opacity: 1;
			transform: scale(1, 1);
		}
		&:hover .boxGallery--txtW {
			@include trans();
			opacity: 1;
			transform: scale(1, 1);

			strong {
				@include trans();
				color: $txt;
			}
		}
		&.active {
			.boxGallery--txt,
			.boxGallery--txtW {
				@include trans();
				opacity: 1;
				transform: scale(1, 1);
			}
		}
	}

	&--txt {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba($orange, 0.8);
		left: 0px;
		top: 0px;
		padding: 40px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		overflow: hidden;
		@include trans();
		opacity: 0;
		transform: scale(0.2, 0.2);
		@include b-M {
			padding: 20px;
		}
		div {
			font-size: 1.6rem;
			line-height: 130%;
			width: 100%;
			color: $white;

			@media (max-width: 1250px) {
				font-size: 1.9vw;
				line-height: 120%;
				letter-spacing: 0.1rem;
			}

			@include b-TL {
				font-size: 2.3vw;
				line-height: 120%;
			}

			@include b-ML {
				font-size: 6vw;
				line-height: 120%;
			}
		}
	}

	&--txtW {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba($white, 0.7);
		left: 0px;
		top: 0px;
		padding: 80px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		overflow: hidden;
		@include trans();
		opacity: 0;
		transform: scale(0.2, 0.2);
		@include b-M {
			padding: 20px;
		}
		div {
			font-size: 1rem;
			width: 100%;
			color: $txt;

			@media (max-width: 1250px) {
				font-size: 1.7vw;
				line-height: 120%;
				letter-spacing: 0.1rem;
			}

			@include b-TL {
				font-size: 2.3vw;
				line-height: 120%;
			}

			@include b-ML {
				font-size: 6vw;
				line-height: 120%;
			}

			h2 {
				margin-bottom: 15px;
				padding-bottom: 15px;
				@include lineDotted(1rem, -5px, $txt, 20px, left);
			}

			strong {
				@include trans();
			}
		}
	}
}
.subBrand {
	position: absolute;
	top: 30px;
	right: 100px;

	img {
		//width: 63px;
		//height: 72px;
		width: 76px;
		height: 86px;
		@include b-ML() {
			width: 63px;
			height: 72px;
		}
	}

	@include b-TS() {
		right: 50px;
	}
	@include b-ML() {
		right: 15px;
	}
}

.foot {
	padding-top: 100px;
	padding-bottom: 100px;
	@include b-ML() {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.brand {
		width: 250px;
		height: 190px;
		display: block;
		@include b-ML() {
			width: 100px;
			height: auto;
		}
	}

	&--txt {
		padding-left: 90px;
		max-width: 895px;
		@include b-TS() {
			padding-left: 50px;
		}
		@include b-ML() {
			padding-left: 20px;
			font-size: 0.8rem;
			letter-spacing: 0.05rem;
		}
		@include b-M() {
			padding-left: 0px;
		}

		a {
			text-decoration: underline;
			@include trans();

			&:hover {
				@include trans();
				color: $orange;
			}
		}

		span {
			font-size: 0.8rem;
			@include b-ML() {
				font-size: 0.6rem;
			}
		}
	}
	.linkBig {
		@include lineDotted(1.5rem, -18px, $black, 40px, left);
		font-size: 1.3rem;

		margin-bottom: 20px;
		padding-bottom: 20px;
		@include b-ML() {
			font-size: 1rem;
			&::before {
				font-size: 1rem;
				bottom: -18px;
				height: 30px;
			}
		}
	}
	.linkSmall {
		//@include lineDotted(1rem, -50px, $black, 37px, left);
		margin-bottom: 40px;
		padding-bottom: 40px;
	}
	.copy {
		font-size: 0.75rem;
		@include lineDotted(0.75rem, 15px, $black, 30px, left);
		&:before {
			max-width: 325px;
		}
	}
}
.detailTxt {
	padding-top: 40px;
	padding-bottom: 40px;
	h2 {
		margin-bottom: 15px;
		padding-bottom: 15px;
		text-transform: uppercase;
		@include lineDotted(1rem, -5px, $txt, 20px, left);
		&:before {
			width: 40% !important;

			@include b-TS() {
				width: 70% !important;
			}
			@include b-ML() {
				width: 100% !important;
			}
		}
	}
}
.ich-speedcarousel {
	position: relative;
	overflow: hidden;
	height: 700px;
	white-space: nowrap;

	@media (max-width: 1300px) {
		height: 500px;
	}
	@include b-ML() {
		height: 300px;
	}
}
.ich-speedcarousel--item,
.ich-speedcarousel--duplication {
	display: inline;
	vertical-align: top;
	float: none;
	height: 700px;
	@media (max-width: 1300px) {
		height: 500px;
	}
	@include b-ML() {
		height: 300px;
	}
	img {
		max-width: none;
		height: 700px;
		width: auto;
		@media (max-width: 1300px) {
			height: 500px;
		}
		@include b-ML() {
			height: 300px;
		}
	}
}
.ich-speedcarousel--wrap {
	white-space: nowrap;
}

.ich-gallery {
	display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: auto;
	@media (min-width: 550px) {
		grid-template-columns: 1fr 1fr;
		
	}
	@media (min-width: 990px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.ich-gallery--item img {
	aspect-ratio: 1 / 1;
	object-fit: cover;
	object-position: center center;
	width: 100%;
}

.uk-transition-fade, .uk-close {
	opacity: 1 !important;
	background-color: white !important;
}


.aboutUs {
	&--txt {
		margin-top: 40px;
		margin-bottom: 40px;
		padding-bottom: 40px;
		@include b-ML() {
			margin-top: 0px;
		}

		position: relative;
		&:before {
			text-align: left;
			position: absolute;
			width: 100%;
			height: 20px;
			left: 0px;
			bottom: -10px;
			color: $txt;
			font-size: 1rem;
			box-sizing: border-box;
			content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ";
			overflow: hidden;
			padding-left: 100px !important;
			padding-right: 100px !important;

			@include b-TS() {
				padding-left: 50px !important;
				padding-right: 50px !important;
			}
			@include b-ML() {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}

		p {
			line-height: 130%;
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}

	&--columns {
		display: flex;
		margin-top: 60px;
		margin-bottom: 80px;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 920px;
    	margin-left: auto;
    	margin-right: auto;
		@include b-ML() {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	.item {
		width: 100%;
		float: none;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: 0px;
		@media (min-width: 650px) {
			flex-wrap: nowrap;
			gap: 60px;
		}
		@media (min-width: 891px) {
			margin-right: 60px;
		}

		@media (max-width: 890px) {
			
			margin-right: 0px;
			margin-bottom: 40px;
			&:first-child {
				margin-right: 40px;
			}
		}
		@include b-M() {
			width: 100%;
			&:first-child {
				margin-right: 0px;
			}
		}
		&:last-child {
			margin-right: 0px;
			margin-bottom: 0px;
		}

		img {
			max-width: 100%;
			margin-bottom: 40px;
			@include b-ML() {
				margin-bottom: 20px;
			}
		}

		h2 {
			font-size: 1.43rem;
		}

		h3 {
			font-size: 0.86rem;
			text-transform: uppercase;
			margin-top: 40px;
			@include b-M() {
				margin-top: 20px;
			}
		}

		em {
			font-size: 0.86rem;
			letter-spacing: 0.1rem;
		}

		p {
			&:last-child {
				margin-bottom: 0px;
			}
		}

		.sublink {
			position: relative;

			margin-bottom: 40px;
			padding-bottom: 40px;

			&:before {
				text-align: left;
				position: absolute;
				width: 100%;
				height: 20px;
				left: 0px;
				bottom: -10px;
				color: $txt;

				box-sizing: border-box;
				content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ";
				overflow: hidden;
			}
		}
	}

	&--logos {
		position: relative;
		padding-top: 85px;
		line-height: 140%;
		@include b-ML() {
			padding-top: 45px;
		}
		&:before {
			text-align: left;
			position: absolute;
			width: calc(100% - 200px);
			left: 100px;
			height: 20px;

			bottom: 0px;
			color: $txt;
			font-size: 1.3rem;
			font-family: CharlesWright-Bold;
			content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ";
			overflow: hidden;
			@include b-TS() {
				width: calc(100% - 100px);
				left: 50px;
			}
			@include b-ML() {
				width: calc(100% - 30px);
				left: 15px;
			}
		}
		&:after {
			text-align: left;
			position: absolute;
			width: calc(100% - 200px);
			height: 20px;
			left: 100px;
			top: 0px;
			color: $txt;
			font-size: 1.3rem;
			font-family: CharlesWright-Bold;
			content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ";
			overflow: hidden;
			@include b-TS() {
				width: calc(100% - 100px);
				left: 50px;
			}
			@include b-ML() {
				width: calc(100% - 30px);
				left: 15px;
			}
		}
	}
	&--logosWrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-top: 85px;
		@include b-ML() {
			margin-top: 45px;
		}
	}
	&--logosWrapInner {
		width: 25%;
		float: left;
		@include b-TL() {
			width: 33.33%;
		}
		@include b-TS() {
			width: 50%;
		}
		@include b-ML() {
			width: 100%;
		}
		img {
			width: 80%;
			height: auto;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 85px;
			@include b-ML() {
				margin-bottom: 45px;
			}
		}
	}
	.logo_dek,
	.logo_prolicht {
		img {
			margin-left: 0px;
			@include b-TS() {
				margin-left: auto;
			}
		}
	}
	.logo_miba {
		img {
			margin-right: 0px;
			@include b-TL() {
				margin-right: auto;
				margin-left: 0px;
			}
			@include b-TS() {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
}
